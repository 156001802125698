import React, { Fragment, useContext, useState } from 'react'



import { MdFamilyRestroom, MdTipsAndUpdates, MdKeyboardArrowRight, MdOutlineKeyboardArrowRight, MdFullscreen, MdPeopleAlt } from 'react-icons/md';
import { IoDocumentTextOutline, IoIosDocument, IoMdArrowDropdown, IoMdBook } from 'react-icons/io';
import { FaPlus } from 'react-icons/fa';
import { AiOutlineQuestionCircle, AiFillFolder, AiFillFile, AiOutlineCloseCircle, AiOutlineSearch } from 'react-icons/ai'

import { BiCalendar, BiEdit } from 'react-icons/bi'




import { useNavigate } from 'react-router-dom';


import { GoDotFill, GoStarFill } from "react-icons/go";
import { GoStar } from "react-icons/go";

import { HiOutlineVideoCamera, HiVideoCamera } from 'react-icons/hi2';

// import AgendarAdmin from './agendar_admin';
import { AiFillCloseCircle } from 'react-icons/ai'
import { RiMapPinLine } from 'react-icons/ri'
import Renderif from '../components/renderif';
import SideMenu from '../components/sidemenu';
import { Service } from '../services/api';
import { AlertContext } from '../context/alert_context';
import { AuthContext } from '../context/auth_context';
import Loader from '../components/loader';
import { dateISO } from '../utils/utils';
import { addHour, format, sameDay } from '@formkit/tempo';





Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}
export default function CitasView() {

    const { notify } = useContext(AlertContext)
    const { user, logout } = useContext(AuthContext)
    const navigate = useNavigate();
    const [state, setState] = useState({
        loading: false,
        init_date: new Date(),
        citas: [],
        show_agendar_cita: false,
        tab: 0,
        show_cita_detail_modal: false,
        show_cita_detail: [],
        show_cita_cancel_modal: false,
        show_cita_reagendar_modal: false,
        show_cita_date_modal: new Date(),
    })

    useState(() => {
        const init = async () => {

            // setState({...state, loading:true})
            let _citas = await Service('GET', 'appointments');
            console.log(_citas)
            if (_citas.status === 401) {
                logout();
                navigate('/')
            }
            // let today = new Date();
            // let next_citas = _citas.data.data.filter((cita)=> new Date(cita.dateOfEvent) > today).sort((citaA, citaB)=> Number(new Date(citaA.dateOfEvent)) - Number(new Date(citaB.dateOfEvent)))
            state.citas = _citas.data
            setState({ ...state })
            // setState({
            //     ...state, 
            //     citas:next_citas,
            //     loading:false
            // })
            // console.log(_citas)
        };
        init();
        return () => { }
    }, [])

    const getCitas = async () => {
        setState({ ...state, loading: true })
        let _citas = await Service('GET', 'Appointment');
        if (_citas.status === 401) {
            logout();
            navigate('/')
        }
        let today = new Date();
        let next_citas = _citas.data.data.filter((cita) => new Date(cita.dateOfEvent) > today).sort((citaA, citaB) => Number(new Date(citaA.dateOfEvent)) - Number(new Date(citaB.dateOfEvent)))

        setState({
            ...state,
            citas: next_citas,
            loading: false
        })
    }
    const onCancelAppontment = async () => {
        let _citaCancel = await Service('DELETE', 'appointments/'+state.show_cita_detail.id);
        console.log(_citaCancel)
        if(_citaCancel.status === 200){
            notify({ title: 'Cancelar Cita', message: 'Su cita ha sido cancelada.', type: 'success' })
        }
    }


    const addDays = (days, date) => {

        date.setDate(date.getDate() + days);
        return date;
    }
    const subDays = (days, date) => {
        date.setDate(date.getDate() - days);
        return date;
    }


    // let date = new Date();
    // console.log(date.addDays(5));


    const y = [0, 30, 60, 90, 120, 150]
    const time_mock = [
        "2024-03-08T09:00:00",
        "2024-03-09T10:00:00",
        "2024-03-10T11:00:00",
        "2024-03-11T12:00:00",
        "2024-03-12T13:00:00",
        "2024-03-13T14:00:00",
        "2024-03-14T15:00:00",
        "2024-03-15T16:00:00",
        "2024-03-16T17:00:00",
    ]
    const time = [
        '9:00 AM',
        '10:00 AM',
        '11:00 AM',
        '12:00 PM',
        '13:00 PM',
        '14:00 PM',
        '15:00 PM',
        '16:00 PM',
        '17:00 PM',
        '18:00 PM',
        '19:00 PM'
    ]
    const times = [
        '09',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
    ]

    var weekday = new Array(7);
    weekday[0] = "Domingo";
    weekday[1] = "Lunes";
    weekday[2] = "Martes";
    weekday[3] = "Miercoles";
    weekday[4] = "Jueves";
    weekday[5] = "Viernes";
    weekday[6] = "Sabado";

    var months = new Array(7);
    months[0] = "Enero";
    months[1] = "Febrero";
    months[2] = "Marzo";
    months[3] = "Abril";
    months[4] = "Mayo";
    months[5] = "Junio";
    months[6] = "Julio";
    months[7] = "Agosto";
    months[8] = "Septiembre";
    months[9] = "Octubre";
    months[10] = "Noviembre";
    months[11] = "Diciembre";

    const time_relation_y = {
        '09:00:00': '0px',
        '09:30:00': '40px',
        '10:00:00': '80px',
        '10:30:00': '120px',
        '11:00:00': '160px',
        '11:30:00': '200px',
        '12:00:00': '240px',
        '12:30:00': '280px',
        '13:00:00': '320px',
        '13:30:00': '360px',
        '14:00:00': '400px',
        '14:30:00': '440px',
        '15:00:00': '480px',
        '15:30:00': '520px',
        '16:00:00': '560px',
        '16:30:00': '600px',
        '17:00:00': '640px',
        '17:30:00': '680px'
    }

    const getRestDays = (day, date, tile_index,) => {


        // if(tile_index === 6){
        //     console.log(tile_index)
        //     console.log(day)
        // }



        // if(tile_index === day){

        //     return date;
        // }


        let a = new Date(state.init_date);


        return subDays(((day) - tile_index), a).getDate();






    }
    const getTileDate = (day, date, tile_index,) => {


        // if(tile_index === 6){
        //     console.log(tile_index)
        //     console.log(day)
        // }



        // if(tile_index === day){

        //     return date;
        // }


        let a = new Date(state.init_date);


        return subDays(((day) - tile_index), a)






    }
    const TileAppointmentCheck = (tile_date) => {


        // let a = Date.parse(new Date())
        // let b = Date.parse(new Date())
        let reserved = false
        let cita;

        let _format_tile_date = format(tile_date, "YYYY-MM-DDTHH:mm:ss", 'es')


        state.citas.map((item, i) => {

            let _format_init_date = item.init.split('.')[0]

            // console.log(_format_tile_date)
            // console.log(_format_init_date)
            // console.log("=====")







            if (_format_init_date === _format_tile_date) {


                reserved = true
                cita = item


            }
        })


        return {
            reserved,
            cita
        }


    }

    const renderCitas = (date) => {




        return state.citas.map((cita, i) => {
            let _init = cita.init.split('.')[0]



            let a = format(_init, "HH:mm")
            let _b = addHour(_init, 1);
            let b = format(_b, "HH:mm")





            if (sameDay(_init, date)) {
                return (<div className='cita_tile cursor-pointer' onClick={() => {

                    // if (cita?.isOnline) {
                    //     window.open(cita?.link, '_blank')
                    // } else {
                    //     window.open('https://maps.app.goo.gl/eQqB6fnq2su46ctr6', '_blank')
                    // }

                    console.log(cita)
                    setState({ ...state, show_cita_detail_modal: true, show_cita_detail: cita })


                }} style={{ height: 80, top: time_relation_y[_init.split('T')[1]] }} key={i}>

                    <div className='d-flex flex-column'>
                        <div className='d-flex align-items-center'>
                            <div className='bv-icon'>
                                <GoDotFill size={26} color='green'></GoDotFill>
                            </div>
                            <div >
                                <div className='f-12 bold '>{a + ' - ' + b}</div>

                            </div>
                        </div>
                        <div>
                            <div className='text-gray f-12 line-0 ps-1'>{cita?.specialist}</div>
                            <div className='d-flex align-items-center'>
                                <div className='bv-icon'>
                                    {cita?.isOnline}
                                    <Renderif isTrue={cita?.isOnline}>
                                        <HiOutlineVideoCamera size={30} color="green"></HiOutlineVideoCamera>
                                    </Renderif>
                                    <Renderif isTrue={!cita?.isOnline}>
                                        <MdPeopleAlt size={30} color="green"></MdPeopleAlt>
                                    </Renderif>
                                </div>

                                <Renderif isTrue={cita?.isOnline}>
                                    <div className='text-gray f-12 ps-1'>Videollamada</div>
                                </Renderif>
                                <Renderif isTrue={!cita?.isOnline}>
                                    <div className='text-gray f-12 ps-1'>Presencial</div>
                                </Renderif>
                            </div>
                        </div>


                    </div>




                </div>)
            }


        })

    }


    return (
        <>

            <Renderif isTrue={state.loading}>
                <Loader></Loader>
            </Renderif>
            <Renderif isTrue={state.show_cita_detail_modal}>
                <div className='overlay d-flex justify-content-center align-items-center'>
                    <div className='bv-modal shadow'>
                        <div className='px-4 pt-3 d-flex align-items-center'>
                            <div className=' f-16 px-4 pt-3 text-gray bolder'>Próxima Cita </div>
                            <div className='col'></div>
                            <div className='bv-icon bv-icon--mid cursor-pointer' onClick={() => {

                                setState({ ...state, show_cita_detail_modal: false })

                            }}> <AiFillCloseCircle size={24} color="#00BF6B"></AiFillCloseCircle></div>
                        </div>
                        <div className='px-4 pb-5'>

                            <div className='px-4 pt-3 image-container'>
                                <div className='f-14 text-gray bolder p-1'>Su próxima cita será el día:</div>
                                <div className='f-14 text-gray bolder p-1'>{state.show_cita_date_modal.addDays(1).toDateString()} a las {state.show_cita_detail?.init?.split('T')[1].slice(0, -8)} horas</div>
                                <div className='f-14 text-gray bolder p-1 flex-center align-items-center' onClick={() => {

                                    if (state.show_cita_detail?.isOnline) {
                                        window.open(state.show_cita_detail?.link, '_blank')
                                    } else {
                                        window.open('https://maps.app.goo.gl/eQqB6fnq2su46ctr6', '_blank')
                                    }

                                }}>Ir a:
                                    <Renderif isTrue={state.show_cita_detail.isOnline}> <div className='bv-cta-small d-flex align-items-center'><HiOutlineVideoCamera size={20} color="green"></HiOutlineVideoCamera>Video Llamada</div></Renderif>
                                    <Renderif isTrue={!state.show_cita_detail.isOnline}> <div className='bv-cta-small d-flex align-items-center'><RiMapPinLine size={20} color="green"></RiMapPinLine>Presencial</div></Renderif>
                                </div>
                                <div className='f-18 text-gray bolder p-1'>Especialista: {state.show_cita_detail?.specialistName}</div>
                                <Renderif isTrue={!state.show_cita_detail.isOnline}> <div className='f-18 text-gray bolder p-1'>Dirección: Av. Humberto Lobo 236-Local 104, Monterrey, Nuevo León, México</div></Renderif>
                                <div className='f-12 text-gray p-1'>Si deseas realizar algún cambio a la cita como: cambio de Especialista, Fecha, Hora, por favor presiona Reagendar: </div>
                            </div>
                            <div className='mt-3  d-flex align-items-center'>
                                <div className={`bv-cta bv-cta--danger`} onClick={() => {
                                    setState({ ...state,show_cita_detail_modal: false, show_cita_cancel_modal: true })
                                }}>
                                    Cancelar Cita

                                </div>
                                <div className='col'></div>
                                <div className={`bv-cta bv-cta--success`} onClick={() => {
                                    setState({ ...state,show_cita_detail_modal: false, show_cita_reagendar_modal: true })
                                }}>
                                    Reagendar

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Renderif>
            <Renderif isTrue={state.show_cita_cancel_modal}>
                <div className='overlay d-flex justify-content-center align-items-center'>
                    <div className='bv-modal shadow'>
                        <div className='px-4 pt-3 d-flex align-items-center'>
                            <div className=' f-16 px-4 pt-3 text-maron bolder'>Cancelar Cita </div>
                            <div className='col'></div>
                            <div className='bv-icon bv-icon--mid cursor-pointer' onClick={() => {

                                setState({ ...state, show_cita_cancel_modal: false })

                            }}> <AiFillCloseCircle size={24} color="#00BF6B"></AiFillCloseCircle></div>
                        </div>
                        <div className='px-4 pb-5'>

                            <div className='px-4 pt-3 image-container'>
                                <div className='f-18 text-gray bolder p-1'>Cita a Cancelar:</div>
                                <div className='f-14 text-gray bolder p-1'>{state.show_cita_date_modal.addDays(1).toDateString()} a las {state.show_cita_detail?.init?.split('T')[1].slice(0, -8)} horas</div>
                                <div className='f-18 text-gray bolder p-1'>Especialista: {state.show_cita_detail?.specialistName}</div>
                                <Renderif isTrue={!state.show_cita_detail.isOnline}> <div className='f-18 text-gray bolder p-1'>Dirección: Av. Humberto Lobo 236-Local 104, Monterrey, Nuevo León, México</div></Renderif>
                                <div className='f-12 text-gray p-1'>Si deseas realizar algún cambio a la cita como: cambio de Especialista, Fecha, Hora, por favor presiona Reagendar: </div>
                            </div>
                            <div className='mt-3  flex-center align-items-center'>
                                <div className={`bv-cta bv-cta--success`} onClick={() => {
                                    onCancelAppontment();
                                    setState({ ...state, show_cita_cancel_modal: false })
                                }}>
                                    Guardar

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Renderif>
            <Renderif isTrue={state.show_cita_reagendar_modal}>
                <div className='overlay d-flex justify-content-center align-items-center'>
                    <div className='bv-modal shadow'>
                        <div className='px-4 pt-3 d-flex align-items-center'>
                            <div className=' f-16 px-4 pt-3 text-gray bolder'>Reagendar Cita </div>
                            <div className='col'></div>
                            <div className='bv-icon bv-icon--mid cursor-pointer' onClick={() => {

                                setState({ ...state, show_cita_reagendar_modal: false })

                            }}> <AiFillCloseCircle size={24} color="#00BF6B"></AiFillCloseCircle></div>
                        </div>
                        <div className='px-4 pb-5'>

                            <div className='px-4 pt-3 image-container'>
                                <div className='f-18 text-gray bolder p-1'>Se cancelará la cita para el día:</div>
                                <div className='f-14 text-gray bolder p-1'>{state.show_cita_date_modal.addDays(1).toDateString()} a las {state.show_cita_detail?.init?.split('T')[1].slice(0, -8)} horas</div>
                                <div className='f-14 text-gray p-1'>Luego seras redirigido para crear otra cita con otro horario.</div>
                                <div className='f-18 text-gray bolder p-1'>Deseas continuar? </div>
                            </div>
                            <div className='mt-3  flex-center align-items-center'>
                                <div className={`bv-cta bv-cta--success`} onClick={() => {
                                    onCancelAppontment();
                                    setState({ ...state, show_cita_reagendar_modal: false, tab: 1 })
                                }}>
                                    Guardar

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Renderif>
            <Renderif isTrue={state.show_agendar_cita}>

                {/* <AgendarAdmin onSchedule={(cita)=>{
                state.show_agendar_cita =false
                getCitas();
                // setState({...state, show_agendar_cita:false})
            }} onCancel={()=>{
                setState({...state, show_agendar_cita:false})
            }}></AgendarAdmin> */}


            </Renderif>


            <div className='container-fluid h-100 d-flex'>


                <SideMenu page={'citas'}></SideMenu>


                <div className='col hide-scroll'>

                    <div className='container-fluid bg-white'>
                        <img className='m-3' width={120} src='/assets/images/logo.png'></img>
                    </div>

                    {/* <div className='container-fluid bg-light'>
                    <div className='container'>
                        <div className='row'>
                            <div className='d-flex  p-3'>
                                <div className='d-flex align-items-center'>
                                        <div className='avatar avatar--mid'></div>
                                        <div className='col ps-3'>
                                            <div className='f-18 bold '>¡Hola! {user.firstName} {user.lastName}.</div>
                                            <div className='text-gray f-14 line-0'>Medicina General</div>
                                        </div>
                                </div>
                                <div className='col'></div>
                            </div>
                        </div>
                    </div>
                </div> */}

                    <Renderif isTrue={state.tab === 0}>
                        <div className='container-fluid container-xl mx-auto px-3'>



                            <div className='d-flex justify-content-end align-items-end py-3'>

                                <div className='tabs'>
                                    <div className='bv-tab bv-tab--active'>Citas</div>
                                    <div className='bv-tab' onClick={() => {
                                        setState({ ...state, tab: 1 })
                                    }}>Especialidades</div>

                                </div>

                                <div className='col'>
                                    {/* <div className='input--light'>
                                        <div className='bv-icon bv-icon--mid'><AiOutlineSearch size={30}></AiOutlineSearch></div>
                                        <input className='bg-transparent' placeholder='Busca Especialista'></input>
                                    </div> */}
                                </div>



                                <div className='col d-none d-lg-flex align-items-center justify-content-end'>
                                    <div className='f-14 text-gray'><span className='bold'>Semana:</span></div>
                                </div>


                                <div className='mx-3 d-none d-lg-flex'>
                                    <div className='input--light'>
                                        <div className='bv-icon bv-icon--mid'><BiCalendar size={30}></BiCalendar></div>
                                        <input type='date' className='bg-transparent' placeholder='Busca Especialista' onChange={(evt) => {

                                            let date = new Date(Date.parse(evt.currentTarget.value))
                                            date.setHours('00', '00', '00')
                                            addDays(1, date)

                                            console.log(date)
                                            setState({ ...state, init_date: date })
                                        }}></input>
                                    </div>
                                </div>


                                <div className='bv-cta bv-cta--success' onClick={() => {
                                    // navigate('/user/agendar')
                                    setState({ ...state, tab: 1 })
                                }}>
                                    Agendar

                                </div>
                                <div className='d-none d-lg-flex' style={{ width: 100 }}></div>
                            </div>

                            <div className='d-flex flex-wrap d-lg-none'>{
                                state.citas.map((cita, i) => {
                                    console.log(cita)
                                    let _init = cita.init.split('.')[0]



                                    let a = format(_init, "HH:mm")
                                    let _b = addHour(_init, 1);
                                    let b = format(_b, "HH:mm")

                                    return (
                                        <div className='cita_tile cita_tile--single' style={{ height: 80 }} key={i} onClick={() => {

                                            // if (cita?.isOnline) {
                                            //     window.open(cita?.link, '_blank')
                                            // } else {
                                            //     window.open('https://maps.app.goo.gl/eQqB6fnq2su46ctr6', '_blank')
                                            // }
                                            console.log(cita)
                                            setState({ ...state, show_cita_detail_modal: true, show_cita_detail: cita, show_cita_date_modal:new Date(cita.init) })

                                        }}>
                                            <div className='d-flex flex-column'>
                                                <div className='d-flex align-items-start'>
                                                    <div className='bv-icon'>
                                                        <GoDotFill size={26} color='green'></GoDotFill>
                                                    </div>
                                                    <div >
                                                        <div className='f-12 bold '>{a + ' - ' + b}</div>

                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='text-gray f-14 line-0 ps-1'>{cita?.specialist}</div>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='bv-icon'>
                                                            <Renderif isTrue={cita?.isOnline}>
                                                                <HiOutlineVideoCamera size={30} color="green"></HiOutlineVideoCamera>
                                                            </Renderif>
                                                            <Renderif isTrue={!cita?.isOnline}>
                                                                <MdPeopleAlt size={30} color="green"></MdPeopleAlt>
                                                            </Renderif>
                                                        </div>
                                                        <Renderif isTrue={cita?.isOnline}>
                                                            <div className='text-gray f-12 ps-1'>Videollamada</div>
                                                        </Renderif>
                                                        <Renderif isTrue={!cita?.isOnline}>
                                                            <div className='text-gray f-12 ps-1'>Presencial</div>
                                                        </Renderif>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }</div>

                            <div className='d-none d-lg-flex'>

                                {
                                    ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'].map((item, i) => {

                                        let tile_date = getTileDate(state.init_date.getDay(), state.init_date.getDate(), i)
                                        let _format_tile_date = format(tile_date, 'YYYY-MM-DDT00:00:00')

                                        return (
                                            <div key={i} className='col'>

                                                <div className={`daytile ${state.init_date.getDay() === i ? 'bg-light' : ''}`}>
                                                    <div className='text-gray f-14'>{item}</div>
                                                    <div className='text-dark f-16 bold'>{getRestDays(state.init_date.getDay(), state.init_date.getDate(), i)}</div>
                                                </div>
                                                <div className='position-relative bg-lightblue mx-1 h-100'>
                                                    {renderCitas(_format_tile_date)}
                                                </div>
                                            </div>
                                        )
                                    })
                                }



                                <div className='' style={{ width: 100 }}>

                                    <div style={{ height: 62 }} className='text-gray f-12 bold p-3'>EST <br></br> GMT-5</div>


                                    {
                                        time.map((item, i) => {
                                            return (
                                                <div key={i} className='text-gray f-12 bold px-3' style={{ height: 80 }}>{item}</div>
                                            )
                                        })
                                    }


                                </div>

                            </div>





                        </div>
                    </Renderif>

                    <Renderif isTrue={state.tab == 1}>
                        <div className='container'>
                            <div className='d-flex p-3'>

                                <div className='tabs'>
                                    <div className='bv-tab' onClick={() => {
                                        setState({ ...state, tab: 0 })
                                    }}>Citas</div>
                                    <div className='bv-tab bv-tab--active'>Especialidades</div>

                                </div>






                            </div>
                            <div className='container px-3'>

                                <div className='mb-2'>
                                    <div className='f-16 text-gray bold'>
                                        Selecciona una especialidad.
                                    </div>
                                </div>

                                <div className='d-flex flex-wrap'>
                                    <div className='col-12 col-md-4 mt-3 pe-3'>

                                        <div className='w-100' >

                                                 

                                            <div className='bg-cover bg-cover--end d-flex flex-column r-10 p-3 ' style={
                                                {backgroundImage: 'url(/assets/images/Especialidades-03.png)', height:400}}>
                                                    <div className='col'></div>
                                                {/* <div className='f-28 bold text-white'>Nutrición</div> */}
                                            </div>
                                            <div className='f-28 bold text-green ps-3 mt-3'>Nutrición</div>
                                            <ul className=''>
                                                <li><div className='f-16 text-gray bold'>Mejora tu salud</div></li>
                                                <li><div className='f-16 text-gray bold'>Bienestar</div></li>
                                                <li><div className='f-16 text-gray bold'>Plan Alimenticio Personalizado</div></li>
                                               
                                            </ul>


                                            <div className='bv-cta bv-cta--success mt-2 w-100 align-self-end f-22' onClick={() => {
                                                if (state.citas.length > 2) {
                                                    notify({ title: 'Agendar', message: 'Ya haz agendado tu limite de citas mensual.', type: 'error' })
                                                    return;
                                                }
                                                navigate('/user/agendar/1')
                                            }}>
                                                Agendar
                                            </div>


                                        </div>


                                        {/* <img className='cursor-pointer' onClick={() => {

                                            if (state.citas.length > 2) {
                                                notify({ title: 'Agendar', message: 'Ya haz agendado tu limite de citas mensual.', type: 'error' })
                                                return;
                                            }
                                            navigate('/user/agendar/1')

                                        }} src='/assets/images/e1.png'></img> */}


                                    </div>


                                    <div className='col-12 col-md-4 mt-3 px-3'>

                                           <div className='w-100' >
                                          
                                            <div className='bg-cover bg-cover--end d-flex flex-column r-10 p-3 ' style={
                                                {backgroundImage: 'url(/assets/images/Especialidades-02.png)', height:400}}>
                                                    <div className='col'></div>
                                                {/* <div className='f-28 bold text-white'>Nutrición</div> */}
                                            </div>

                                            <div className='f-28 bold text-green ps-3 mt-3'>Ginecología</div>
                                            <ul>
                                                <li><div className='f-16 text-gray bold'>Mejora tu salud</div></li>
                                                <li><div className='f-16 text-gray bold'>Bienestar</div></li>
                                                <li><div className='f-16 text-gray bold'>Plan Alimenticio Personalizado</div></li>
                                               
                                            </ul>


                                            <div className='bv-cta bv-cta--success mt-2 w-100 align-self-end f-22' onClick={() => {
                                                if (state.citas.length > 2) {
                                                    notify({ title: 'Agendar', message: 'Ya haz agendado tu limite de citas mensual.', type: 'error' })
                                                    return;
                                                }
                                                navigate('/user/agendar/2')
                                            }}>
                                                Agendar
                                            </div>


                                        </div>


                                        {/* <img className='cursor-pointer' onClick={() => {
                                            if (state.citas.length > 2) {
                                                notify({ title: 'Agendar', message: 'Ya haz agendado tu limite de citas mensual.', type: 'error' })
                                                return;
                                            }
                                            navigate('/user/agendar/2')
                                        }} src='/assets/images/e2.png'></img> */}


                                    </div>

                                    <div className='col-12 col-md-4 mt-3 ps-3'>

                                           <div className='w-100' >
                                           {/* <img className='w-100' src='/assets/images/Especialidades-04.png'></img> */}
                                            <div className='bg-cover bg-cover--end d-flex flex-column r-10 p-3 ' style={
                                                {backgroundImage: 'url(/assets/images/Especialidades-04.png)', height:400}}>
                                                    <div className='col'></div>
                                                {/* <div className='f-28 bold text-white'>Nutrición</div> */}
                                            </div>

                                            <div className='f-28 bold text-green ps-3 mt-3'>Psicología</div>
                                            <ul>
                                                <li><div className='f-16 text-gray bold'>Mejora tu salud</div></li>
                                                <li><div className='f-16 text-gray bold'>Bienestar</div></li>
                                                <li><div className='f-16 text-gray bold'>Plan Alimenticio Personalizado</div></li>
                                               
                                            </ul>


                                            <div className='bv-cta bv-cta--success mt-2 w-100 align-self-end f-22' onClick={() => {
                                                     if (state.citas.length > 2) {
                                                        notify({ title: 'Agendar', message: 'Ya haz agendado tu limite de citas mensual.', type: 'error' })
                                                        return;
                                                    }
                                                    navigate('/user/agendar/3')
                                            }}>
                                                Agendar
                                            </div>


                                        </div>


                                        {/* <img className='cursor-pointer' onClick={() => {
                                            if (state.citas.length > 2) {
                                                notify({ title: 'Agendar', message: 'Ya haz agendado tu limite de citas mensual.', type: 'error' })
                                                return;
                                            }
                                            navigate('/user/agendar/3')
                                        }} src='/assets/images/e3.png'></img> */}


                                    </div>
                                </div>
                            </div>
                        </div>
                    </Renderif>

                    <div className='my-5'></div>
                </div>
            </div>
        </>

    )
}



export function Rating({ value = 5 }) {

    return (
        <div className='d-flex'>
            <div className='bv-icon '>

                <Renderif isTrue={value > 0}>
                    <GoStarFill color='#FFA800'></GoStarFill>
                </Renderif>
                <Renderif isTrue={value < 1}>
                    <GoStar color='#CCC'></GoStar>
                </Renderif>

            </div>

            <div className='bv-icon'>
                <Renderif isTrue={value > 1}>
                    <GoStarFill color='#FFA800'></GoStarFill>
                </Renderif>
                <Renderif isTrue={value < 2}>
                    <GoStar color='#CCC'></GoStar>
                </Renderif>
            </div>

            <div className='bv-icon'>
                <Renderif isTrue={value > 2}>
                    <GoStarFill color='#FFA800'></GoStarFill>
                </Renderif>
                <Renderif isTrue={value < 3}>
                    <GoStar color='#CCC'></GoStar>
                </Renderif>
            </div>

            <div className='bv-icon'>
                <Renderif isTrue={value > 3}>
                    <GoStarFill color='#FFA800'></GoStarFill>
                </Renderif>
                <Renderif isTrue={value < 4}>
                    <GoStar color='#CCC'></GoStar>
                </Renderif>
            </div>

            <div className='bv-icon'>
                <Renderif isTrue={value > 4}>
                    <GoStarFill color='#FFA800'></GoStarFill>
                </Renderif>
                <Renderif isTrue={value < 5}>
                    <GoStar color='#CCC'></GoStar>
                </Renderif>
            </div>
        </div>
    )
}


