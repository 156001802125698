import React, { useContext, useEffect, useState } from 'react'
import SideMenu from '../components/sidemenu'
import { useNavigate } from 'react-router-dom';
import { BiArrowBack } from "react-icons/bi";
import Renderif from '../components/renderif';
import { AuthContext } from '../context/auth_context';
import { AiFillCloseCircle } from 'react-icons/ai';
import { Service } from '../services/api';
import { MdEdit } from "react-icons/md";

export default function PrefilView() {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext)
  console.log(user)

  

  const [state, setState] = useState({
    tab: 0,
    show_cita_detail_id: null,
 
    edit_member: false,
    plan_cancel_modal: false,

  })
  const [patient, setPatient] = useState(null)

  useEffect(()=>{
    init()
    return() => {}
  },[])

  const init = async () => {
    
    let patient = await Service('GET',`patients/${user.id}`)
    console.log(patient)
    setPatient(patient.data)

    
  }

 const onSavePatient = async () => {
  let body = {
    // ...state.patient
    firstName:patient.firstName,
    lastName:patient.lastName,
    email:patient.email,
    phone:patient.phone,
    ocupation:patient.ocupation,
    civilState:patient.civilState,
    emergencyContactName:patient.emergencyContactName,
    emergencyContactLastName:patient.emergencyContactLastName,
    emergencyContactPhone:patient.emergencyContactPhone,
    emergencyContactEmail:patient.emergencyContactEmail,
    emergencyContactBirthdate:patient.emergencyContactBirthdate,
    emergencyContactRelationship:patient.emergencyContactRelationship,
    objective:patient.objective,
    branchOffices: [],
    birthdate: patient.birthdate,
}

    state.edit_member = false;
    let _update = await Service('PUT', `patients/${user.id}`, body)
    console.log(_update)
    init()

 }


  return (
    <div className='container-fluid d-flex h-100'>

      <div>
        <SideMenu page={'perfil'}></SideMenu>
      </div>

      <Renderif isTrue={state.plan_cancel_modal}>
        <div className='overlay d-flex justify-content-center align-items-center'>
          <div className='bv-modal shadow'>
            <div className='px-4 pt-3 d-flex align-items-center'>
              <div className=' f-16 px-4 pt-3 text-gray bolder'>Cancelar Plan </div>
              <div className='col'></div>
              <div className='bv-icon bv-icon--mid cursor-pointer' onClick={() => {

                setState({ ...state, plan_cancel_modal: false })

              }}> <AiFillCloseCircle size={24} color="#00BF6B"></AiFillCloseCircle></div>
            </div>
            <div className='px-4 pb-5'>

              <div className='px-4 pt-3 image-container'>
                <div className='f-18 text-gray bolder p-1'>Estás segur@ de cancelar tu plan? </div>
              </div>
              <div className='mt-3  d-flex align-items-center'>
                <div className={`bv-cta bv-cta--danger`} onClick={() => {
                  setState({ ...state, plan_cancel_modal: false })
                }}>
                  Lo pensare de nuevo

                </div>
                <div className='col'></div>
                <div className={`bv-cta bv-cta--success`} onClick={async () => {
                  console.log("cancelanding");
                  let _planCancel = await Service('DELETE', 'plans/cancel-suscription');
                  console.log(_planCancel)
                  if (_planCancel.status === 200) {
                    navigate('/user')
                  }
                  setState({ ...state, plan_cancel_modal: false })
                }}>
                  Cancelar Plan

                </div>
              </div>
            </div>

          </div>
        </div>
      </Renderif>

      <div className='col overflow-auto'>

        <div className='container-fluid bg-white'>
          <img className='m-3' width={120} src='/assets/images/logo.png'></img>
        </div>

        {/* <div className='container  p-3'>
          <div className='d-flex align-items-center'>
            <div className='d-flex align-items-center'>
              <div className='avatar avatar--big'></div>
              <div className='ps-3'>
                <div className='f-28 text-gray bold line-0'>{user?.firstName}</div>
                <div className='f-14 text-gray line-0'>{user?.email}</div>
              </div>
            </div>

            <div className='col'></div>
            <div className='pe-3'>

              <Renderif isTrue={state.edit_member === false}>
                <div className='bv-cta bv-cta--success w-100' onClick={() => {
                  setState({ ...state, edit_member: true })
                }}>
                  <div>Editar</div>
                </div>
              </Renderif>

            </div>
            <div className='col-2'></div>

          </div>
        </div> */}
        {/* 
        <div className='container px-3'>
            <div className='tabs'>
                <div className='bv-tab bv-tab--active'>Tips</div>
                <div className='bv-tab'>Videos</div>
                <div className='bv-tab'>Aprende más</div>
            </div>
        </div> */}

        <div className='container px-3 my-5'>
          <div className='row'>

            <div className='col-12 col-sm-7 pe-2'>

              <div className=' bg-light r-5 p-3'>

                <div className='d-flex p-1 bold'>
                  <div className='text-gray f-18'>
                    Perfil
                  </div>
                  <div className='col'>

                  </div>
                  <Renderif isTrue={state.edit_member === false}>
                    <div className='edit-btn d-flex-center cursor-pointer' onClick={() => {
                      setState({ ...state, edit_member: true })
                    }}>
                      <MdEdit size={20} className='me-1' />Editar
                    </div>
                  </Renderif>

                </div>
              

                <div className='d-flex mt-4'>
                  <div className='col'>

                    <div className=''>
                      <div className='text-dark f-14 bold mb-1 ps-1'>Nombre:</div>
                      <Renderif isTrue={!state.edit_member}>
                        <div className='text-gray f-14 ps-1'>{patient?.firstName}</div>
                      </Renderif>
                      <Renderif isTrue={state.edit_member}>
                        <input className='input--light' value={patient?.firstName} onChange={(evt) => {
                          patient.firstName = evt.currentTarget.value
                          setPatient({...patient})
                        }}></input>
                      </Renderif>
                    </div>


                    <div className='mt-3'>
                      <div className='text-dark f-14 bold mb-1 ps-1'>Teléfono:</div>
                      <Renderif isTrue={!state.edit_member}>
                        <div className='text-gray f-14 ps-1'>{patient?.emergencyContactPhone}</div>
                      </Renderif>
                      <Renderif isTrue={state.edit_member}>
                        <input className='input--light' value={patient?.emergencyContactPhone} onChange={(evt) => {
                           patient.emergencyContactPhone = evt.currentTarget.value
                           setPatient({...patient})
                        }}></input>
                      </Renderif>
                    </div>

                    <div className='mt-3'>
                      <div className='text-dark f-14 bold mb-1 ps-1'>Ocupación:</div>
                      <Renderif isTrue={!state.edit_member}>
                        <div className='text-gray f-14 ps-1'>{patient?.ocupation}</div>
                      </Renderif>
                      <Renderif isTrue={state.edit_member}>
                        <input className='input--light' value={patient?.ocupation} onChange={(evt) => {
                          patient.ocupation = evt.currentTarget.value;
                          setPatient({...patient})
                        }}></input>
                      </Renderif>
                    </div>


                    {/* <div className='mt-3'>
                      <div className='text-dark f-14 bold mb-1 ps-1'>Genero:</div>
                      <Renderif isTrue={!state.edit_member}>
                        <div className='text-gray f-14 ps-1'>{user?.gender}</div>
                      </Renderif>
                      <Renderif isTrue={state.edit_member}>
                        <input className='input--light' defaultValue={'Hombre'} onChange={(evt) => {
                        }}></input>
                        <select className='input--light'>
                          <option>Hombre</option>
                          <option>Mujer</option>
                        </select>
                      </Renderif>
                    </div> */}

                    {/* <div className='mt-3'>
                      <div className='text-dark f-14 bold mb-1 ps-1'>Altura:</div>
                      <Renderif isTrue={!state.edit_member}>
                        <div className='text-gray f-14 ps-1'>{user?.height}</div>
                      </Renderif>
                      <Renderif isTrue={state.edit_member}>
                        <input className='input--light' defaultValue={'1.70'} onChange={(evt) => {
                          // state.member_active.name = evt.currentTarget.value;
                          // setState({...state})
                        }}></input>
                      </Renderif>
                    </div> */}

                  </div>
                  <div className='col ps-3'>

                    <div className=''>
                      <div className='text-dark f-14 bold mb-1 ps-1'>Apellido:</div>
                      <Renderif isTrue={!state.edit_member}>
                        <div className='text-gray f-14 ps-1'>{patient?.lastName}</div>
                      </Renderif>
                      <Renderif isTrue={state.edit_member}>
                        <input className='input--light' value={patient?.lastName} onChange={(evt) => {
                          patient.lastName = evt.currentTarget.value;
                          setPatient({...patient})
                        }}></input>
                      </Renderif>
                    </div>

                    <div className='mt-3'>
                      <div className='text-dark f-14 bold mb-1 ps-1'>Fecha de Nacimiento:</div>
                      <Renderif isTrue={!state.edit_member}>
                        <div className='text-gray f-14 ps-1'>{patient?.birthdate.split('T')[0]}</div>
                      </Renderif>
                      <Renderif isTrue={state.edit_member}>
                      <input className="input--light" value={state.patient?.birthdate.split('T')[0]} type={'date'} onChange={(evt)=>{
                                         patient.birthdate = evt.currentTarget.value;
                                         setPatient({...patient})
                                    }} placeholder=""></input>
                      </Renderif>
                    </div>

                    <div className='mt-3'>
                      <div className='text-dark f-14 bold mb-1 ps-1'>Estado Civil:</div>
                      <Renderif isTrue={!state.edit_member}>
                        <div className='text-gray f-14 ps-1'>{patient?.civilState}</div>
                      </Renderif>
                      <Renderif isTrue={state.edit_member}>
                          <select className='input--light' value={patient?.civilState} onChange={(evt)=>{
                            patient.civilState = evt.currentTarget.value;
                            setPatient({...patient})
                          }}>
                                    <option value="">Seleccionar</option>
                                        <option value="Solter@">Solter@</option>
                                        <option value="Casad@">Casad@</option>
                                        <option value="Viud@">Viud@</option>
                                        <option value="Union libre">Union libre</option>
                                        <option value="N/D">N/D</option>
                          </select>
                      </Renderif>
                    </div>

                    {/* <div className=''>
                      <div className='text-dark f-14 bold mb-1 ps-1'>Peso:</div>
                      <Renderif isTrue={!state.edit_member}>
                        <div className='text-gray f-14 ps-1'>{user?.weight}</div>
                      </Renderif>
                      <Renderif isTrue={state.edit_member}>
                        <input className='input--light' defaultValue={'Aldana'} onChange={(evt) => {
                          // state.member_active.name = evt.currentTarget.value;
                          // setState({...state})
                        }}></input>
                      </Renderif>
                    </div> */}

                  </div>
                </div>
              </div>

              <div className='d-sm-flex r-5 mt-3'>
                <div className='bg-light p-3 me-sm-3 col'>
                  <div className='d-flex'>
                    <div className='f-16 bolder'>Suscripciones</div>
                    <div className='col'>

                    </div>
                    <Renderif isTrue={state.edit_member === false}>
                      <div className='edit-btn d-flex-center cursor-pointer' onClick={() => {
                        setState({ ...state, edit_member: true })
                      }}>
                        <MdEdit size={20} className='me-1' />Editar
                      </div>
                    </Renderif>
                  </div>
                  <div>

                    {user?.subscriptions.map((item, i) => {
                      return (
                        <div key={i} className='d-flex mt-3'>
                          <div className='text-gray'> {item.name}</div>
                          <div className='col'></div>
                          <Renderif isTrue={state.edit_member === true}>
                            <div className='f-14 text-red text-decoration-underline cursor-pointer' onClick={() => {
                              setState({ ...state, plan_cancel_modal: true })
                            }}>
                              Cancelar Plan
                            </div>
                          </Renderif>

                        </div>
                      )
                    })}

                  </div>
                </div>
                <div className='bg-light p-3 mt-3 mt-sm-0'>
                  <div className='f-16 bolder text-gray text-center'>
                    Sesiones Disponibles
                  </div>
                  <div className='f-24 bolder text-gray text-center'>
                    1
                  </div>
                  <div className='f-16 text-center cursor-pointer text-green text-underline'>
                    Agregar Sesión
                  </div>
                </div>
              </div>



              <Renderif isTrue={state.edit_member === true}>
                <div className='d-flex mt-3'>
                  <div className='col'></div>
                  <div className='bv-cta bv-cta--danger' onClick={() => {
                    
                    setState({ ...state, edit_member: false })
                  }}>
                    <div>Cancelar</div>
                  </div>
                  <div className='mx-1'></div>
                  <div className='bv-cta bv-cta--success' onClick={() => {
                    onSavePatient()
                    // setState({ ...state, edit_member: false })
                  }}>
                    <div>Guardar</div>
                  </div>
                </div>
              </Renderif>




            </div>
            {/* <div className='col-3 pe-2'>
              <div className='bg-light r-5 p-3'>

                <div className=''>
                  <div className='text-dark f-14 bold mb-1 ps-1'>Peso:</div>
                  <Renderif isTrue={!state.edit_member}>
                    <div className='text-gray f-14 ps-1'>52 Kg</div>
                  </Renderif>
                  <Renderif isTrue={state.edit_member}>
                    <input className='input--light' defaultValue={'52 Kg'} onChange={(evt) => {
                      state.member_active.name = evt.currentTarget.value;
                      setState({ ...state })
                    }}></input>
                  </Renderif>
                </div>

                <div className='mt-2'>
                  <div className='text-dark f-14 bold mb-1 ps-1'>Estarura:</div>
                  <Renderif isTrue={!state.edit_member}>
                    <div className='text-gray f-14 ps-1'>164 Cm</div>
                  </Renderif>
                  <Renderif isTrue={state.edit_member}>
                    <input className='input--light' defaultValue={'164 Cm'} onChange={(evt) => {
                      state.member_active.name = evt.currentTarget.value;
                      setState({ ...state })
                    }}></input>
                  </Renderif>
                </div>

                <div className='mt-2'>
                  <div className='text-dark f-14 bold mb-1 ps-1'>Padecimientos:</div>
                  <Renderif isTrue={!state.edit_member}>
                    <div className='text-gray f-14 ps-1'>Ninguno</div>
                  </Renderif>
                  <Renderif isTrue={state.edit_member}>
                    <input className='input--light' defaultValue={'Ninguno'} onChange={(evt) => {
                      state.member_active.name = evt.currentTarget.value;
                      setState({ ...state })
                    }}></input>
                  </Renderif>
                </div>

                <div className='mt-2'>
                  <div className='text-dark f-14 bold mb-1 ps-1'>Alergias:</div>
                  <Renderif isTrue={!state.edit_member}>
                    <div className='text-gray f-14 ps-1'>Ninguna</div>
                  </Renderif>
                  <Renderif isTrue={state.edit_member}>
                    <input className='input--light' defaultValue={'Ninguna'} onChange={(evt) => {
                      state.member_active.name = evt.currentTarget.value;
                      setState({ ...state })
                    }}></input>
                  </Renderif>
                </div>

              </div>

            </div> */}

          </div>
        </div>


      </div>

    </div>
  )
}
