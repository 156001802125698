import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Login, Register } from '../services/api'
import { AuthContext } from '../context/auth_context'
import { AlertContext } from '../context/alert_context'
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import Renderif from '../components/renderif'
import { IoIosArrowBack } from "react-icons/io";

export default function RegisterView() {
  const navigate = useNavigate()

  const { setUser } = useContext(AuthContext)
  const { notify } = useContext(AlertContext)

  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    passCofirmed: "",
    phone: "",
    ocupation: "",
    civilState: "",
    showPassword: false
  })

  const checkPassword = (password) => {
    var re = /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[#?!@$%^&*-.#]).{8,}$/;
    return re.test(password);
  }

  const onRegister = async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const organizationId = urlParams.get('organizationId')



    if (
      state.email === '' ||
      state.firstname === '' ||
      state.lastname === '' ||
      state.phone === '' ||
      state.password === '' ||
      state.passCofirmed === ''

    ) {
      notify({ title: 'Error', message: 'Todos los campos son obligatorios', type: 'error' })
      return;
    }

    if (state.password !== state.passCofirmed) {
      notify({ title: 'Registro', message: 'Las contraseñas no coinciden', type: 'error' })
      return;
    }

    if (!checkPassword(state.passCofirmed)) {
      console.log("validat")
      // setState({...state, error:'La contraseña debe contener 8 caracteres al menos una letra mayuscula, minuscula, numero, caracter especial, #?!@$%^&*-.#'})
      notify({ title: 'Registro', message: 'La contraseña debe contener 8 caracteres, al menos una letra mayúscula, minúscula y un símbolo.', type: 'error' })
      return;
    }

    let body = {
      firstName: state.firstName,
      lastName: state.lastName,
      email: state.email,
      password: state.passCofirmed,
      phone: state.phone,
      ocupation: "",
      civilState: "",
      organizationId:organizationId===null?undefined:organizationId
    }

    // console.log(body)

    let _register = await Register(body)

    if (_register.status !== 200) {
      notify({ title: 'Error!', message: 'No ha sido posible crear tu cuenta', type: 'error' })
      return
    }

    let _user = await Login({ email: state.email, password: state.password })
    notify({ title: 'Hola!', message: `Bienvenido ${state.firstName}`, type: 'success' })

    setUser({ user: _user.data })
    navigate('/user')
  }

  return (
    // <div className='login_view container-fluid overflow-auto'>
    <div className='container-fluid overflow-auto d-flex h-100 '>

      {/* <div className='container'>
        <img className='d-block mx-auto pt-3' width={250} src='assets/images/logo.png'></img>
      </div> */}

      {/* <div className='login_view_form'>

        <div className='f-32 bold text-gray mb-3'>Registro</div>

        <div className='mt-0'>
          <div className='text-gray'>Nombre</div>
          <div>
            <input value={state.firstName} onChange={(evt) => { setState({ ...state, firstName: evt.currentTarget.value }) }} className='input--light' type='text'></input>
          </div>
        </div>

        <div className='mt-2'>
          <div className='text-gray'>Apellidos</div>
          <div>
            <input value={state.lastName} onChange={(evt) => { setState({ ...state, lastName: evt.currentTarget.value }) }} className='input--light' type='text'></input>
          </div>
        </div>


        <div className='mt-2'>
          <div className='text-gray'>Correo electrónico</div>
          <div>
            <input value={state.email} onChange={(evt) => { setState({ ...state, email: evt.currentTarget.value }) }} className='input--light' type='text'></input>
          </div>
        </div>

        <div className='mt-2'>
          <div className='text-gray'>Télefono</div>
          <div>
            <input value={state.phone} onChange={(evt) => { setState({ ...state, phone: evt.currentTarget.value }) }} className='input--light' type='text'></input>
          </div>
        </div>

        <div className='mt-2'>
          <div className='text-gray'>Contraeña</div>
          <div className='bv-icon-v2'>
            <input value={state.password} onChange={(evt) => { setState({ ...state, password: evt.currentTarget.value }) }} className='input--light' type={`${state.showPassword ? 'text' : 'password'}`}></input>
            <Renderif isTrue={state.showPassword === true}>
              <div className='bv-icon bv-icon--mid mx-2 mb-2 cursor-pointer' onClick={() => {

                setState({ ...state, showPassword: false })

              }}>
                <BsEye size={30}></BsEye>
              </div>
            </Renderif>

            <Renderif isTrue={state.showPassword === false}>
              <div className='bv-icon bv-icon--mid mx-2 mb-2 cursor-pointer' onClick={() => {

                setState({ ...state, showPassword: true })

              }}>
                <BsEyeSlash size={30}></BsEyeSlash>
              </div>
            </Renderif>
          </div>
        </div>

        <div className='mt-2'>
          <div className='text-gray'>Confirmar contraseña</div>
          <div className='bv-icon-v2'>
            <input value={state.passCofirmed} onChange={(evt) => { setState({ ...state, passCofirmed: evt.currentTarget.value }) }} className='input--light' type={`${state.showPassword ? 'text' : 'password'}`}></input>
            <Renderif isTrue={state.showPassword === true}>
              <div className='bv-icon bv-icon--mid mx-2 mb-2 cursor-pointer' onClick={() => {

                setState({ ...state, showPassword: false })

              }}>
                <BsEye size={30}></BsEye>
              </div>
            </Renderif>

            <Renderif isTrue={state.showPassword === false}>
              <div className='bv-icon bv-icon--mid mx-2 mb-2 cursor-pointer' onClick={() => {

                setState({ ...state, showPassword: true })

              }}>
                <BsEyeSlash size={30}></BsEyeSlash>
              </div>
            </Renderif>
          </div>
        </div>

        <div className='mt-3'>
          <div className='bv-cta bv-cta--success w-100' onClick={() => {
            onRegister();

          }}>
            <div>Entrar</div>
          </div>
        </div>

        <div className='mt-3'>
          <div className='text-decoration-underline text-gray cursor-pointer text-center'>¿Olvidaste tu contraseña?</div>
        </div>

      </div> */}
      <div className='col-sm-7 tip-full-login ' style={{ backgroundImage: ` url(assets/images/login-bg.jpg)` }}>

      </div>
      <div className=' col-sm-5 col-12 d-flex-center'>

        <div className='login_view_form'>
          <div className='container'>
            <img className='d-block mx-auto pt-3' width={250} src='assets/images/logo.png'></img>
          </div>
          <div className='f-32 bold text-gray mb-3'>Registro</div>

          <div className='mt-0'>
            <div className='text-gray'>Nombre</div>
            <div>
              <input value={state.firstName} onChange={(evt) => { setState({ ...state, firstName: evt.currentTarget.value }) }} className='input--light' type='text'></input>
            </div>
          </div>

          <div className='mt-2'>
            <div className='text-gray'>Apellidos</div>
            <div>
              <input value={state.lastName} onChange={(evt) => { setState({ ...state, lastName: evt.currentTarget.value }) }} className='input--light' type='text'></input>
            </div>
          </div>


          <div className='mt-2'>
            <div className='text-gray'>Correo electrónico</div>
            <div>
              <input value={state.email} onChange={(evt) => { setState({ ...state, email: evt.currentTarget.value }) }} className='input--light' type='text'></input>
            </div>
          </div>

          <div className='mt-2'>
            <div className='text-gray'>Télefono</div>
            <div>
              <input value={state.phone} onChange={(evt) => { setState({ ...state, phone: evt.currentTarget.value }) }} className='input--light' type='text'></input>
            </div>
          </div>

          <div className='mt-2'>
            <div className='text-gray'>Contraeña</div>
            <div className='bv-icon-v2'>
              <input value={state.password} onChange={(evt) => { setState({ ...state, password: evt.currentTarget.value }) }} className='input--light' type={`${state.showPassword ? 'text' : 'password'}`}></input>
              <Renderif isTrue={state.showPassword === true}>
                <div className='bv-icon bv-icon--mid mx-2 mb-2 cursor-pointer' onClick={() => {

                  setState({ ...state, showPassword: false })

                }}>
                  <BsEye size={30}></BsEye>
                </div>
              </Renderif>

              <Renderif isTrue={state.showPassword === false}>
                <div className='bv-icon bv-icon--mid mx-2 mb-2 cursor-pointer' onClick={() => {

                  setState({ ...state, showPassword: true })

                }}>
                  <BsEyeSlash size={30}></BsEyeSlash>
                </div>
              </Renderif>
            </div>
          </div>

          <div className='mt-2'>
            <div className='text-gray'>Confirmar contraseña</div>
            <div className='bv-icon-v2'>
              <input value={state.passCofirmed} onChange={(evt) => { setState({ ...state, passCofirmed: evt.currentTarget.value }) }} className='input--light' type={`${state.showPassword ? 'text' : 'password'}`}></input>
              <Renderif isTrue={state.showPassword === true}>
                <div className='bv-icon bv-icon--mid mx-2 mb-2 cursor-pointer' onClick={() => {

                  setState({ ...state, showPassword: false })

                }}>
                  <BsEye size={30}></BsEye>
                </div>
              </Renderif>

              <Renderif isTrue={state.showPassword === false}>
                <div className='bv-icon bv-icon--mid mx-2 mb-2 cursor-pointer' onClick={() => {

                  setState({ ...state, showPassword: true })

                }}>
                  <BsEyeSlash size={30}></BsEyeSlash>
                </div>
              </Renderif>
            </div>
          </div>

          <div className='mt-3'>
            <div className='bv-cta bv-cta--success w-100' onClick={() => {
              onRegister();

            }}>
              <div>Registrarme</div>
            </div>
          </div>

          <div className='mt-3'>
            <div className=' text-gray cursor-pointer text-center' onClick={() => {
              navigate('/')

            }}><IoIosArrowBack />Regresar</div>
          </div>

        </div>
      </div>

    </div>
  )
}
