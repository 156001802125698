import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Login } from '../services/api'
import { AuthContext } from '../context/auth_context'
import { AlertContext } from '../context/alert_context'
import Renderif from '../components/renderif'

export default function LoginView() {
  const navigate = useNavigate()

  const { setUser } = useContext(AuthContext)
  const { notify } = useContext(AlertContext)

  const [state, setState] = useState({
    email: 'hi.izakc@gmail.com',
    password: 'Aa@123456',
    inf: false,
  })

  const onLogin = async () => {
    let user = await Login({ ...state })
    console.log(user)
    if (user.status !== 200) {
      notify({ title: 'Error!', message: 'Ocurrio un error al iniciar sesión, por favor intenta de nuevo más tarde.', type: 'error' })
      return
    }

    setUser({ user: user.data })
    navigate('/user')
  }

  return (
    // <div className='login_view container-fluid'>
    <div className='container-fluid d-flex h-100'>

      {/* <div className='container'>
        <img className='d-block mx-auto pt-5' width={250} src='assets/images/logo.png'></img>
      </div> */}

      {/* <div className='login_view_form'>

        <div className='mt-2'>
          <div className='text-gray'>Correo electrónico</div>
          <div>
            <input value={state.email} onChange={(evt) => { setState({ ...state, email: evt.currentTarget.value }) }} className='input--light' type='text'></input>
          </div>
        </div>

        <div className='mt-2'>
          <div className='text-gray'>Contraseña</div>
          <div>
            <input value={state.password} onChange={(evt) => { setState({ ...state, password: evt.currentTarget.value }) }} className='input--light' type='password'></input>
          </div>
        </div>

        <div className='mt-3'>
          <div className='bv-cta bv-cta--success w-100' onClick={() => {
            onLogin();

          }}>
            <div>Entrar</div>
          </div>
        </div>

        <div className='mt-3'>
          <div className='text-decoration-underline text-gray cursor-pointer text-center'>
            ¿No tienes cuenta? Regístrate <span className='cursor-pointer text-green text-decoration-underline bolder' onClick={() => {
              navigate('/register')
            }}>Aquí</span>
          </div>
          <div className='text-decoration-underline text-gray cursor-pointer text-center' onClick={() => {
            navigate('/recoverpass')
          }}>¿Olvidaste tu contraseña?</div>
        </div>

      </div> */}
      <div className='col-sm-7 tip-full-login ' style={{ backgroundImage: ` url(assets/images/login-bg.jpg)` }}>
      </div>
      <div className=' col-sm-5 col-12 d-flex-center'>
        <div className='login_view_form'>
          <div className='container'>
            <img className='d-block mx-auto' width={250} src='assets/images/logo.png'></img>
          </div>
          <Renderif isTrue={state.inf === false}>

            <div className='mt-2'>
              <div className='text-gray'>Correo electrónico</div>
              <div>
                <input value={state.email} onChange={(evt) => { setState({ ...state, email: evt.currentTarget.value }) }} className='input--light' type='text'></input>
              </div>
            </div>

            <div className='mt-2'>
              <div className='text-gray'>Contraseña</div>
              <div>
                <input value={state.password} onChange={(evt) => { setState({ ...state, password: evt.currentTarget.value }) }} className='input--light' type='password'></input>
              </div>
            </div>

            <div className='mt-3'>
              <div className='bv-cta bv-cta--success w-100' onClick={() => {
                onLogin();

              }}>
                <div>Ingresar</div>
              </div>
            </div>

            <div className='mt-5'>
              <div className=' text-gray cursor-pointer text-center mt-3'>
                ¿No tienes cuenta? Regístrate <span className='cursor-pointer text-green text-decoration-underline bolder' onClick={() => {
                  navigate('/register')
                }}>Aquí</span>
              </div>
              <div className='text-decoration-underline text-gray cursor-pointer text-center mt-3' onClick={() => {
                navigate('/recoverpass')
              }}>¿Olvidaste tu contraseña?</div>
            </div>

            <div className='mt-5'>
              <div className='text-decoration-underline text-green cursor-pointer text-center mt-3 bold' onClick={() => {
                setState({ ...state, inf: true })
              }}>¿Quieres conocer MyDietApp?</div>
            </div>
          </Renderif>
          <Renderif isTrue={state.inf}>
            <div className='overflow-auto inf'>
              <div className='text-center text-green f-26'>
                ¿Quienes somos?
              </div>
              <div className='text-justify text-gray f-14 my-2'>
                Somos un equipo de especialistas comprometidos y dedicados constantemente a estudiar e investigar qué y cómo informarte para ayudarte a que tengas una mejor salud y estilo de vida.  Nuestro principal objetivo es que tú mismo tengas la capacidad y claridad de cómo cuidarte ya sea para que prevengas o alivies padecimientos.
              </div>
              <div className='text-center text-green f-26 my-2'>
                ¿Qué es MyDiet App?
              </div>
              <div className='text-justify text-gray f-14'>
                Es una aplicación en la cual aprendes a cuidar tu salud, previniendo o aliviando padecimientos, enseñándote de manera muy clara y desde la raíz, la razón y causa de ellos.  Día a día cuentas con tips, videos y documentos, que paso a paso de manera muy práctica y lógica, vas aprendiendo cómo y qué alimentos afectan positiva o negativamente nuestro cuerpo y nuestra mente.
                Dentro de la aplicación cuentas también con herramientas que te ayudan a tener opciones personalizadas y prácticas para decidir y organizar tu plan de alimentación diario o semanal.  Estos planes se ajustan a tu perfil, ya sea tu género, edad, objetivo, nutrientes adecuados, tiempo disponible para preparar, etc.
                Cuentas también con indicadores para que diariamente y a través del tiempo puedas valorar tu desempeño.  Que puedas tener muy claro y consiente, qué estás haciendo y como tu cuerpo y mente están obteniendo mejor salud.
                Si lo deseas, tienes la opción de contar con un acompañamiento más personalizado. Puedes asesorarte o consultarte con uno de nuestros especialistas, agendando dentro de la misma aplicación, para que lleven tú y tu especialista, dentro de la misma aplicación, los indicadores y objetivos a lograr para tu mejor salud física y mental.
                Estarás siempre informada e informado de qué, cuándo y cómo comer tus alimentos, para que estos contribuyan positivamente en tu cuerpo y mente.  Te enseñaremos qué y cuales ejercicios físicos o mentales también contribuyen en este proceso.
                Tendrás un espacio donde te ayudaremos a llevar el registro de tus marcadores de laboratorio, enseñándote a leerlos y entenderlos de manera clara, y que aprendas qué actividades y nutrientes contribuirán de manera directa a mejorarlos.
              </div>
            </div>
            <div className='mt-3'>
              <div className='bv-cta bv-cta--success w-100' onClick={() => {
                setState({ ...state, inf: false })

              }}>
                <div>Regresar</div>
              </div>
            </div>
          </Renderif>
        </div>
      </div>

    </div>
  )
}
